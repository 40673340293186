/**
 * Edge Learn term label filter
 * Clicking on a tag label triggers a selection in the filter select element
 * ===========================================================
 */

(()=>{
    const edgeLearnTermLabelFilter = () => {
        const termLabels = document.getElementsByClassName('edge-learn-term-label');
        const form = document.getElementById('search-filter-form-6855');
        const categorySelect = document.querySelector('.sf-field-category .sf-input-select');
        const categorySelectOptions = categorySelect.getElementsByTagName('option');
        const coinSelect = document.querySelector('.sf-field-taxonomy-coin .sf-input-select');
        const coinSelectOptions = coinSelect.getElementsByTagName('option');
        const submit =  document.createElement('input');
        submit.type = 'submit';
        submit.style.opacity = '0';
        submit.style.position = 'absolute';
        submit.value = 'submit';
        form.appendChild(submit);
        for (let termLabel of termLabels) {
            termLabel.addEventListener('click', (e) => {
                e.preventDefault();
                if (termLabel.dataset.tax === 'category') {
                    for (let option of categorySelectOptions) {
                        if (termLabel.dataset.slug === option.value) {
                            categorySelect.selectedIndex = option.index;
                        }
                    }
                } else if (termLabel.dataset.tax === 'coin') {
                    for (let option of coinSelectOptions) {
                        if (termLabel.dataset.slug === option.value) {
                            coinSelect.selectedIndex = option.index;
                        }
                    }
                }
                submit.click();
            });
        }
    }
    edgeLearnTermLabelFilter();
})();
